exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-tsx": () => import("./../../../src/pages/a-propos.tsx" /* webpackChunkName: "component---src-pages-a-propos-tsx" */),
  "component---src-pages-cgu-tsx": () => import("./../../../src/pages/cgu.tsx" /* webpackChunkName: "component---src-pages-cgu-tsx" */),
  "component---src-pages-cgv-tsx": () => import("./../../../src/pages/cgv.tsx" /* webpackChunkName: "component---src-pages-cgv-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maladie-chronique-tsx": () => import("./../../../src/pages/maladie-chronique.tsx" /* webpackChunkName: "component---src-pages-maladie-chronique-tsx" */),
  "component---src-pages-particuliers-tsx": () => import("./../../../src/pages/particuliers.tsx" /* webpackChunkName: "component---src-pages-particuliers-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-qui-sommes-nous-tsx": () => import("./../../../src/pages/qui-sommes-nous.tsx" /* webpackChunkName: "component---src-pages-qui-sommes-nous-tsx" */)
}

